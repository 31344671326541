<template>
  <v-container fluid style="padding: 0">
    <UserHeader :filter="filter" :roles="roles" @update-filter="updateFilter" @showError="showError"
      @completed="inviteCompleted" :activeUserCount="activeUserCount" :pendingUserCount="pendingInvitesCount"
      :requestedUserCount="requestedUserCount" />
    <v-card class="py-6 px-6 mt-3" rounded="lg" elevation="0" width="100%">
      <v-row justify="space-between" class="align-center">
        <v-col cols="12" md="auto" class="d-flex align-center">
          <SearchComponent @update:search="setSearchFilter"></SearchComponent>
          <ProjectUserFilter></ProjectUserFilter>
        </v-col>
        <v-col cols="12" md="auto">
          <v-row justify="end" class="align-center">
            <SettingsMenu :filterItems="filteredMenuHeaders" />
          </v-row>
        </v-col>
      </v-row>
      <UserTable :filteredHeaders="filteredHeaders" :items="filteredItem" :itemKey="itemKey" @delete-item="onDelete" />
    </v-card>
    <DiscardDialog v-model="dialogVisible" :title="dialogTitle" @close="onDialogClose" @handleConfirmClick="onConfirm">
      <template v-slot:content>
        <v-flex class="mt-4">
          <p class="text-start">
            {{ dialogDescription }}
          </p>
        </v-flex>
      </template>
      <template v-slot:footer>
        <v-row>
          <v-col cols="6">
            <v-btn dark large width="100%" class="text-capitalize font-weight-bold black--text mt-2" color="gray-100"
              @click="dialogVisible = false">
              {{ $t('discard_cancel') }}
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn dark large width="100%" class="text-capitalize font-weight-bold white--text mt-2" color="danger"
              @click="onConfirm">
              {{ $t('discard_confirm') }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </DiscardDialog>
  </v-container>
</template>

<script>
import { mapGetters, createNamespacedHelpers, mapActions as mapProjectActions } from 'vuex';
import UserHeader from '@/components/User/UserHeader';
import makeRoleService from '@/services/api/role';
import ProjectUserFilter from '@/components/Project/ProjectUserFilter.vue';
import SearchComponent from '@/components/Project/SearchComponent.vue';
import UserTable from '@/components/User/UserTable.vue';
import SettingsMenu from '@/components/Project/SettingsMenu.vue';
import DiscardDialog from '@/components/base/DiscardDialog.vue';
import { users } from '@/constants/data.js';
import makeOrgService from '@/services/api/org';
import { showSuccessToast, showErrorToast } from '@/utils/toast';

const { mapActions, mapState } = createNamespacedHelpers('org');

export default {
  name: 'Users',
  components: {
    UserHeader,
    ProjectUserFilter,
    SearchComponent,
    UserTable,
    DiscardDialog,
    SettingsMenu,
  },
  data() {
    return {
      dialogVisible: false,
      dialogTitle: '',
      dialogDescription: '',
      currentItem: null,
      membersState: {
        isLoading: false,
        hasError: false,
        errorMessage: '',
      },
      activeMembers: [],
      pendingUsers: [],
      requestedUsers: [],
      activeUserCount: 0,
      users: users,
      pendingUserCount: 0,
      requestedUserCount: 0,
      items: [],
      roles: [],
      filter: 'active',
      itemKey: 'uid',
      searchFilter: '',
      headers: [
        {
          text: this.$t('name'),
          align: 'start',
          sortable: true,
          value: 'name',
          class: 'elevation-0 rounded-l-lg',
          checked: true,
        },
        {
          text: this.$t('role'),
          value: 'role',
          sortable: true,
          checked: true,
        },
        {
          text: this.$t('tags'),
          value: 'tag',
          sortable: true,
          checked: true,
        },
        {
          text: this.$t('email'),
          value: 'email',
          sortable: true,
          checked: true,
        },
        {
          text: this.$t('lastActivity'),
          value: 'lastActivity',
          sortable: true,
          checked: true,
        },
        {
          text: this.$t('projectsName'),
          value: 'project',
          sortable: true,
          class: 'rounded-r-lg',
          checked: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['isMenuCollapsed']),
    ...mapGetters({
      currentAccount: 'user/currentAccount',
    }),
    ...mapGetters({
      holderAccount: 'user/user',
    }),
    ...mapState(['pendingInvites']),
    ...mapGetters({
      projects: 'project/projects'
    }),
    pendingInvitesCount() {
      return this.pendingInvites?.length || 0;
    },
    filteredItem() {
      let filtered = this.getFilteredMembers();
      if (this.searchFilter.length > 0) {
        filtered = this.applySearchFilter(filtered);
      }
      return filtered;
    },
    filteredHeaders() {
      return this.headers.filter((header) => {
        const filterItem = this.filteredMenuHeaders.find((item) => item.text === header.text);
        return filterItem ? filterItem.checked : true;
      });
    },
    filteredMenuHeaders() {
      const filtered = this.headers.filter((header) => header.text != 'Actions');
      return filtered;
    },
    getActiveUserCount() {
      return this.activeMembers.length;
    },
  },
  methods: {
    ...mapActions(['getPendingInvites', 'deleteInvite', 'removeUser', 'removeUserOrg']),
    ...mapProjectActions({
      get: 'project/get'
    }),
    updateFilter(newFilter) {
      this.filter = newFilter;
    },
    setSearchFilter(searchText) {
      this.searchFilter = searchText;
    },
    getFilteredMembers() {
      switch (this.filter) {
        case 'active':
          return this.activeMembers;
        case 'pending':
          return this.pendingInvites;
        default:
          return this.requestedUsers;
      }
    },
    applySearchFilter(members) {
      return members.filter((item) => this.matchesFilter(item));
    },
    matchesFilter(item) {
      const lowerCaseFilter = this.searchFilter.toLowerCase();
      const name = `${item.firstName} ${item.lastName}`;

      const nameMatch = name.toLowerCase().includes(lowerCaseFilter);

      return nameMatch;
    },
    async getProjects(param) {
      await this.get(param);
    },
    async showError(errorMessage) {
      await this.$swal({
        title: errorMessage,
        icon: 'error',
        showConfirmButton: false,
        position: 'top-end',
        timer: 2000,
        toast: true,
      });
    },
    async inviteCompleted() {
      try {
        await this.$swal({
          title: this.$t('inviteSent'),
          icon: 'success',
          showConfirmButton: false,
          position: 'top-end',
          timer: 3000,
          toast: true,
        });
        await this.getPendingInvites(this.currentAccount.handle);
      } catch (error) {
        await this.$swal({
          title: this.$t('errorOccurred'),
          text: error.message,
          icon: 'error',
          showConfirmButton: true,
          position: 'top-end',
          timer: 3000,
          toast: true,
        });
      }
    },
    onDelete(item) {
      this.currentItem = item;
      if (this.filter === 'active') {
        this.dialogTitle = this.$t('user_delete_title');
        this.dialogDescription = this.$t('user_delete_description');
      } else if (this.filter === 'pending') {
        this.dialogTitle = this.$t('invite_discard_title');
        this.dialogDescription = this.$t('invite_discard_description');
      } else {
        this.dialogTitle = this.$t('request_decline_title');
      }
      this.dialogVisible = true;
    },
    onDialogClose() {
      this.dialogVisible = false;
    },
    async onConfirm() {
      if (this.filter === 'active') {
        try {
          const userId = this.currentItem.uid;
          await this.deleteUserOrg(this.currentAccount.handle, userId, { userId });
          this.dialogVisible = false;
          await this.init(this.currentAccount.handle);
          showSuccessToast(
            this.$swal,
            this.$t('memberDeleted', {
              username: this.currentItem.firstName + ' ' + this.currentItem.lastName,
              orgname: this.currentAccount.name,
            })
          );
        } catch (error) {
          showErrorToast(
            this.$swal,
            this.$t('memberNotDeleted', {
              username: this.currentItem.firstName + ' ' + this.currentItem.lastName,
              orgname: this.currentAccount.name,
            })
          );
        }
      } else if (this.filter === 'pending') {
        try {
          await this.deleteInvite({ handle: this.currentAccount.handle, email: this.currentItem.email });
          this.dialogVisible = false;
          await this.init(this.currentAccount.handle);
          showSuccessToast(
            this.$swal,
            this.$t('memberDeleted', {
              username: this.currentItem.email,
              orgname: this.currentAccount.name,
            })
          );
        } catch (error) {
          showErrorToast(
            this.$swal,
            this.$t('memberNotDeleted', {
              username: this.currentItem.email,
              orgname: this.currentAccount.name,
            })
          );
        }

      }

    },
    async getMembers(per_page, next_page, handle) {
      const orgService = makeOrgService(this.$api);
      this.membersState.isLoading = true;
      try {
        const response = await orgService.getUsers(handle, { per_page: per_page, next_page: next_page });
        this.activeMembers = response.data.users;
        this.activeUserCount = this.getActiveUserCount;
        this.membersState.isLoading = false;
      } catch (err) {
        this.membersState.isLoading = false;
        this.membersState.hasError = false;
        this.membersState.errorMessage = err.message;
      }
    },
    async deleteUserOrg(handle, userId, payload) {
      const orgService = makeOrgService(this.$api);
      try {
        await orgService.removeUserOrg(handle, userId, payload);
      } catch (err) {
        this.showError(err.response.data.errors);
      }
    },
    async getRoles(handle) {
      //* Retrieve roles for the Organization only to minimize unnecessary requests.
      if (this.holderAccount.handle == handle) return;

      const roleService = makeRoleService(this.$api);

      await roleService
        .getRoles(handle)
        .then((response) => {
          this.roles = response.data.roles;
        })
        .catch((err) => {
          this.showError(err.response.data.errors);
        });
    },
    handleColumnReset() {
      this.headers.map((header) => {
        header.checked = true;
      });
    },
    async init(handle) {
      await this.getMembers(30, 0, handle);
      await this.getProjects({ handle })
      await this.getPendingInvites(handle);
      await this.getRoles(handle);
    },
  },
  async mounted() {
    await this.init(this.currentAccount.handle);
  },
  async beforeRouteUpdate(to, from, next) {
    const handle = to.params.handle;
    if (handle && handle !== from.params.handle) {
      try {
        this.activeMembers = [];
        this.roles = [];
        await this.init(handle);
        next();
      } catch (error) {
        next();
      }
    } else {
      next();
    }
  },
}
</script>